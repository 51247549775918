type FeatureFlags = {
    squareRadius: boolean;
};

const STAGE = process.env.BIGDATR_STYLE_STAGE;
export default function useStyleFeatureFlags(): FeatureFlags {
    if (STAGE === 'production') {
        // make sure values here are true only when we are about to deploy features
        return {
            squareRadius: false
        };
    }
    if (STAGE === 'staging') {
        return {
            squareRadius: true
        };
    }
    // dev/local
    return {
        squareRadius: true
    };
}

import React from 'react';
import styled from 'styled-components';
import useStyleFeatureFlags from '../useStyleFeatureFlags';
import {Flex} from '../../layout';

interface MultiProps<Value> {
    multi: true;
    onChange: (val: Array<Value>) => void;
    options: Array<{
        value: Value;
        label?: React.ReactNode;
        'aria-label'?: string;
    }>;
    value?: Array<Value>;
    borderRadiusLeft?: boolean;
    afterItems?: React.ReactNode;
}

interface SingleProps<Value> {
    multi: false;
    onChange: (val: Value) => void;
    options: Array<{
        value: Value;
        label?: React.ReactNode;
        'aria-label'?: string;
    }>;
    value?: Value;
    borderRadiusLeft?: boolean;
    afterItems?: React.ReactNode;
}

export default function ToggleSet<Value>(props: SingleProps<Value> | MultiProps<Value>) {
    const {afterItems, borderRadiusLeft = true} = props;

    const selected = new Set(new Array<Value>().concat(props.value ?? []));
    return (
        <Flex>
            {props.options.map((item, index) => {
                const {value, label} = item;
                let ariaLabel = item['aria-label'];
                if (!ariaLabel && typeof label === 'string') ariaLabel = label;

                return (
                    <ToggleItem
                        active={selected.has(value)}
                        borderRadiusLeft={borderRadiusLeft}
                        role="switch"
                        aria-label={ariaLabel}
                        aria-checked={selected.has(value)}
                        onClick={() => {
                            if (!props.multi) return props.onChange(value);

                            if (selected.has(value)) {
                                selected.delete(value);
                            } else {
                                selected.add(value);
                            }
                            props.onChange([...selected]);
                        }}
                        key={`${value}${index}`}
                    >
                        <>{label || value}</>
                    </ToggleItem>
                );
            })}
            {afterItems}
        </Flex>
    );
}

const {squareRadius} = useStyleFeatureFlags();
export const ToggleItem = styled.button<{
    active: boolean;
    separate?: boolean;
    borderRadiusLeft?: boolean;
}>((p) => {
    const {active, separate, borderRadiusLeft, theme} = p;
    const {colors, borderRadiuses} = theme;
    const borderRadius = squareRadius ? borderRadiuses.round : '1rem';
    return `
        border-left: 0;
        display: inline-block;
        padding: .25rem .75rem;
        line-height: 1.5rem;
        margin-left: -1px;
        margin-right: -1px;
        height: 2rem;
        background-color: ${colors.background};
        box-shadow: inset 0 0 0 2px ${colors.outline};
        cursor: pointer;
        color: inherit;
        border-radius: ${separate ? borderRadius : '0rem'};

        &:first-child {
            border-radius: ${separate ? borderRadius : `${borderRadius} 0 0 ${borderRadius}`};
            margin-left: 0;
            ${separate ? `border-radius: ${borderRadius} !important;` : ''}
        }

        ${
            borderRadiusLeft
                ? `&:last-child {
                    border-radius: ${
                        separate ? borderRadius : `0 ${borderRadius} ${borderRadius} 0`
                    };
                    margin-right: 0;
                }`
                : ''
        }

        &:focus-visible {
            text-decoration: underline;
        }

        &:hover {
            outline: none;
            color: ${colors.brand};
            box-shadow: inset 0 0 0 2px ${colors.brand};
            position: relative;
        }

        ${
            active
                ? `&, &:hover {
                    color: ${theme.colors.white};
                    box-shadow: inset 0 0 0 2px ${theme.colors.brand};
                    background-color: ${theme.colors.brand};
                    position: relative;
                }`
                : ''
        }
`;
});
